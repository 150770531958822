import React, { useRef } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import PlayIcon from './play-icon'

type StatIconsProps = {
  iconName?: string
  value: number
  direction?: 'right' | 'left'
  className?: any
}
const StatIcons = ({
  iconName,
  value,
  direction,
  className,
}: StatIconsProps) => {
  const initialValue = useRef(value)
  const isNegGoalDiff = iconName?.includes('Goal') && value < 0

  return (
    <Icons className={className} direction={direction}>
      {Array.from({ length: Math.abs(value) }, (__, i) => (
        <motion.span
          initial={i >= initialValue.current && { scale: 0 }}
          animate={{ scale: 1 }}
          transition={{
            delay: (i - initialValue.current) * 0.08,
            type: 'spring',
            stiffness: 500,
            mass: 0.5,
            damping: 12,
          }}
          key={i}
        >
          <PlayIcon text={isNegGoalDiff ? 'Own Goal' : iconName} />
        </motion.span>
      ))}
    </Icons>
  )
}

export default StatIcons

type IconsProps = {
  direction?: 'right' | 'left'
}
//The flex stuff (vs just word wrap) is necessary for animation
const Icons = styled.div<IconsProps>`
  display: flex;
  flex-direction: ${(p) => (p.direction === 'right' ? 'row' : 'row-reverse')};
  flex-wrap: wrap;
  width: 100%;
`
