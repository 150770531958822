import { RosterPlayerStat } from '../../types'
import { InfoStatItem } from './types'
import React from 'react'
import styled from 'styled-components'
import InfoStatsItem from './info-stats-item'

type InfoStatsProps = {
  stats: RosterPlayerStat[]
}
const InfoStats = ({ stats }: InfoStatsProps) => {
  const [foulsCommitted, foulsSuffered] = stats.filter(
    (stat) => stat.name === 'foulsCommitted' || stat.name === 'foulsSuffered'
  )
  const [onTarget, shots] = stats.filter(
    (stat) => stat.name === 'shotsOnTarget' || stat.name === 'totalShots'
  )
  const [offsides] = stats.filter((stat) => stat.name === 'offsides')
  const [ownGoals, assists, goals] = stats.filter(
    (stat) =>
      stat.name === 'ownGoals' ||
      stat.name === 'goalAssists' ||
      stat.name === 'totalGoals'
  )
  const [reds, yellows] = stats.filter(
    (stat) => stat.name === 'redCards' || stat.name === 'yellowCards'
  )
  const [goalsConceded, saves] = stats.filter(
    (stat) => stat.name === 'goalsConceded' || stat.name === 'saves'
  )

  //keepers have saves instead of offsides in stat data
  const isKeeper = !offsides && saves

  const sharedStats = [
    {
      caption: 'Scoring',
      stats: [
        {
          value: goals.value,
          iconName: 'Goal',
          label: 'Goals',
        },
        {
          value: assists.value,
          iconName: 'Assist',
          label: 'Assists',
        },
        {
          value: ownGoals.value,
          iconName: 'Own Goal',
          label: ownGoals.value ? 'Own Goals' : null,
        },
      ],
    },
    {
      caption: 'Shots',
      stats: [
        {
          value: shots.value,
          iconName: 'Blocked',
          label: 'Total',
        },
        {
          value: onTarget.value,
          iconName: 'Blocked',
          label: 'On Target',
        },
      ],
    },
    {
      caption: 'Fouls',
      stats: [
        {
          value: foulsCommitted.value,
          iconName: 'Foul',
          label: 'Committed',
        },
        {
          value: foulsSuffered.value,
          iconName: 'Foul',
          label: 'Suffered',
        },
      ],
    },
  ]

  const statItems: InfoStatItem[] = []
  if (isKeeper) {
    statItems.push({
      caption: 'Keeping',
      stats: [
        {
          value: saves.value,
          iconName: 'Shot On Target',
          label: 'Saves',
        },
        {
          value: goalsConceded.value,
          iconName: 'Own Goal',
          label: 'Goals Conceded',
        },
      ],
    })
    //For keepers, hide uncommon stats with 0 value (goals, shots, etc)
    sharedStats.forEach((statItem) => {
      const filteredStats = statItem.stats.filter((stat) => stat.value)
      if (filteredStats.length) {
        statItems.push({
          caption: statItem.caption,
          stats: filteredStats,
        })
      }
    })
  } else {
    statItems.push(...sharedStats, {
      caption: 'Offsides',
      stats: [
        {
          value: offsides.value,
          iconName: 'Offsides',
          label: 'Offsides',
        },
      ],
    })
  }

  //only show cards section if there are some
  if (yellows.value || reds.value) {
    statItems.push({
      caption: 'Cards',
      stats: [
        {
          value: yellows.value,
          iconName: 'Yellow Card',
          label: yellows.value ? 'Yellow' : null,
        },
        {
          value: reds.value,
          iconName: 'Red Card',
          label: reds.value ? 'Red' : null,
        },
      ],
    })
  }

  return (
    <Container>
      <Table>
        {statItems.map((item) => (
          <InfoStatsItem
            caption={item.caption}
            stats={item.stats}
            key={item.caption}
          />
        ))}
      </Table>
    </Container>
  )
}

export default InfoStats

const Container = styled.div`
  grid-column: 1 / -1;
`
const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`
