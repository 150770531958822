import React from 'react'
import styled from 'styled-components'
import FluidClubLogo from 'src/common/components/team-logos/fluid-club-logo'

type PlayerInfoProps = {
  name: string
  number: string
  team: string
}
const PlayerInfo = ({ name, number, team }: PlayerInfoProps) => {
  return (
    <Container>
      <Name>{name}</Name>
      <LogoNumber>
        #{number}
        <LogoWrapper>
          <FluidClubLogo clubName={team} sizes="50px" />
        </LogoWrapper>
      </LogoNumber>
    </Container>
  )
}

export default PlayerInfo

const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const LogoWrapper = styled.div`
  width: 30px;
  margin-left: 4px;
`
const LogoNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.2rem;
  opacity: 0.9;
  letter-spacing: 0.05ch;
`
const Name = styled.div`
  font-size: 1.1rem;
  font-weight: bold;
  letter-spacing: 0.05ch;
  text-align: right;
`
