import React from 'react'
import Img from 'gatsby-image'
import GenericLogo from 'src/common/generic-logo'
import useClubLogos from '../../hooks/use-fluid-club-logos'
import { getClubInfo } from 'src/common/clubs'

/*
  TODO:
  ---
  * Set color for generic crest
*/

type ClubLogoProps = {
  clubName: string
  sizes?: string
}
const FluidClubLogo = ({ clubName, sizes }: ClubLogoProps) => {
  const logoData = useClubLogos()
  const { displayName } = getClubInfo(clubName)

  const logoIndex = logoData.findIndex(
    (logo) =>
      logo.childImageSharp?.fluid?.originalName ===
      `${displayName.toUpperCase()}.png`
  )

  return (
    <>
      {logoIndex >= 0 ? (
        <Img
          draggable={false}
          fadeIn={false}
          fluid={{
            //I have a dream, that one day, the graphql types will work.
            //Or maybe I'm just doing it wrong?
            ...(logoData[logoIndex].childImageSharp?.fluid as any), //:(
            sizes,
          }}
        />
      ) : (
        GenericLogo
      )}
    </>
  )
}

export default FluidClubLogo
