import { TeamColors, Roster, RosterPlayer } from '../../types'
import React from 'react'
import styled from 'styled-components'
import Field from './field'
import FieldPlayers from './field-players'
import WaitingDots from 'src/common/components/waiting-dots'

// const { x, y, name, number, isHome } = player

type FormationProps = {
  teamColors: TeamColors
  homeRoster: Roster
  awayRoster: Roster
  // rosters: Roster[]
}
const Formation = ({ teamColors, homeRoster, awayRoster }: FormationProps) => {
  return (
    <Field>
      {homeRoster.roster?.map((player) => {
        if (player.starter && player.layoutCoordinates) {
          return (
            <FieldPlayers
              teamColor={teamColors.homeColor}
              player={player}
              roster={homeRoster}
              key={`${player.jersey}-${player.athlete.lastName}`}
            />
          )
        }
        return null
      }) ?? (
        <WaitingText x="42" y="42">
          Waiting for home lineup
        </WaitingText>
      )}
      {awayRoster.roster?.map((player) => {
        if (player.starter && player.layoutCoordinates) {
          return (
            <FieldPlayers
              teamColor={teamColors.awayColor}
              player={player}
              roster={awayRoster}
              key={`${player.jersey}-${player.athlete.lastName}`}
            />
          )
        }
        return null
      }) ?? (
        <WaitingText x="42" y="84">
          Waiting for away lineup
        </WaitingText>
      )}
    </Field>
  )
}

export default Formation

const WaitingText = styled.text`
  stroke-width: 0;
  text-anchor: middle;
  font-weight: 400;
  /* fill: ${(p) => p.theme.palette.primary.light}; */
  fill: rgba(255, 255, 255, 0.85);
  /* fill: ${(p) => p.theme.palette.lightGold}; */
  font-size: 3px;
`
