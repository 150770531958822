import { TeamColors, RosterPlayer, RosterTeam, Roster } from '../../types'
import React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import InfoPopover from '../info-popover'
import PlayIcon from '../../play-icon'
import { getGoalCardStats } from '../utils'
import { yellowCardColor, redCardColor } from '../../styles'

type FieldPlayersProps = {
  // teamColors: TeamColors
  teamColor: string
  // fieldPlayers: any[]
  player: RosterPlayer
  // team: RosterTeam
  roster: Roster
}
const FieldPlayers = ({ teamColor, player, roster }: FieldPlayersProps) => {
  const { jersey, athlete, subbedOut, layoutCoordinates } = player
  const [x, y] = layoutCoordinates
  // const goalCardStats = getGoalCardStats(player.stats)

  return (
    <InfoPopover player={player} color={teamColor} roster={roster}>
      <Player
        teamColor={teamColor}
        onClick={() => console.log(`Clicked on ${athlete.lastName}`)}
      >
        <NumberCircle animate={{ x, y }} initial={false} r={2.5} />
        <NumberText
          animate={{ x, y: y + 1.17 }}
          initial={false}
          teamColor={teamColor}
        >
          {jersey}
        </NumberText>
        <NameText
          animate={{ x, y: y + 5.5 }}
          initial={false}
          isLong={athlete.lastName?.length > 10}
        >
          {athlete.lastName || athlete.displayName.split(' ').reverse()[0]}
        </NameText>
        {/* need the explicit check to avoid truth {didSub: false} */}
        {subbedOut === true && (
          <>
            <SubbedCircle
              animate={{ x: x - 3.7, y }}
              initial={false}
              r={1.75}
            />
            <SubbedIcon animate={{ x: x - 3.7, y: y + 0.7 }} initial={false}>
              &#xE092;
            </SubbedIcon>
          </>
        )}
        {player.stats &&
          getGoalCardStats(player.stats).map((stat, i) => {
            return (
              <TextIcon
                animate={{ x: x + 4.2 + i * 3, y: y + 0.7 }}
                initial={false}
                fill={getStatIconFillColor(stat.abbreviation)}
                key={`${stat.displayName}-${i}`}
              >
                {stat.abbreviation === 'G' || stat.abbreviation === 'OG'
                  ? '\uE093'
                  : '\uE060'}
              </TextIcon>
            )
          })}
      </Player>
    </InfoPopover>
  )
}

export default FieldPlayers

const getStatIconFillColor = (abbrev: string) => {
  if (abbrev === 'G') return '#ffffff'
  if (abbrev === 'OG') return redCardColor
  if (abbrev === 'YC') return yellowCardColor
  if (abbrev === 'RC') return redCardColor
  return 'inherit'
}

type PlayerProps = {
  teamColor: string
}
const Player = styled.g<PlayerProps>`
  user-select: none;
  fill: ${(p) => p.teamColor};
  cursor: pointer;
`
const NumberCircle = styled(motion.circle)`
  stroke-width: 0;
`
const SubbedCircle = styled(motion.circle)`
  /* fill: #fff; */
  /* fill: ${(p) => p.theme.palette.background.default}cc; */
  fill: ${(p) => p.theme.palette.background.default};
  stroke-width: 0.1;
  /* stroke-width: 0; */
  stroke: #000;
  stroke: ${(p) => p.theme.palette.primary.light};
`
const TextIcon = styled(motion.text)`
  font-family: 'ESPN-Icon-Font';
  font-size: 2.65px;
  text-anchor: middle;
  stroke-width: 0;
  font-weight: 400;
  /* fill: #fff;
  fill: ${(p) => p.theme.palette.primary.light}; */
`
const SubbedIcon = styled(TextIcon)`
  fill: #000;
  fill: ${(p) => p.theme.palette.primary.light};
`
type NumberTextProps = {
  teamColor: string
}
const NumberText = styled(motion.text)<NumberTextProps>`
  fill: ${(p) => p.theme.palette.getContrastText(p.teamColor)};
  font-size: 3px;
  stroke-width: 0;
  text-anchor: middle;
  font-weight: 700;
`
type NameTextProps = {
  isLong: boolean
}
const NameText = styled(motion.text)<NameTextProps>`
  stroke-width: 0;
  text-anchor: middle;
  font-weight: 400;
  /* fill: ${(p) => p.theme.palette.primary.light}; */
  fill: rgba(255, 255, 255, 0.85);
  /* fill: ${(p) => p.theme.palette.lightGold}; */
  font-size: ${(p) => (p.isLong ? '2.4px' : '3px')};
`
