import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import color from 'color'
import { TeamColors, StatValues } from '../../types'
import { transition } from '../../utils'
import Donut from './donut'
import Typography from '@material-ui/core/Typography'
import { ChartLabel } from '../../styles'

type PassingProps = {
  teamColors: TeamColors
  statValues: StatValues[]
}
const Passing = ({ teamColors, statValues }: PassingProps) => {
  const [away, setAway] = useState(0)
  // if (statValues[0] === undefined || statValues[1] === undefined) return null

  const homeTotal = statValues[1].homeVal ?? 0
  const awayTotal = statValues[1].awayVal ?? 0
  // const homeTotal = 25
  // const awayTotal = away
  // const homeTotal = 0
  // const awayTotal = 0
  const homePercentOfMax =
    homeTotal + awayTotal > 0 ? homeTotal / Math.max(homeTotal, awayTotal) : 0
  const awayPercentOfMax =
    homeTotal + awayTotal > 0 ? awayTotal / Math.max(homeTotal, awayTotal) : 0

  return (
    <Container>
      {/* <button onClick={() => setAway((away) => away + 1)}>increase</button> */}
      <PassLabel>Passes</PassLabel>
      <DonutContainer>
        {/* {!(homeTotal + awayTotal) && ( */}
        <Donut
          color={teamColors.homeColor}
          percent={(statValues[0].homeVal ?? 0) / 100}
          total={homeTotal}
          percentOfMax={homePercentOfMax}
          isHome
        />
        {/* )} */}
        {/* {!!(awayTotal + homeTotal) && ( */}
        <Donut
          color={teamColors.awayColor}
          percent={(statValues[0].awayVal ?? 0) / 100}
          total={awayTotal}
          percentOfMax={awayPercentOfMax}
        />
        {/* )} */}
      </DonutContainer>
    </Container>
  )
}

export default Passing

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 250px;
`
const PassLabel = styled(ChartLabel)`
  /* padding-bottom: 0; */
`
// const ChartLabel = styled(Typography)`
//   text-align: center;
//   text-transform: uppercase;
//   font-size: 0.875rem;
//   line-height: 1em;
// `
const DonutContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 0 ${(p) => p.theme.spacing(1)}px;
`
