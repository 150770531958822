import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { ChartValueText } from '../../styles'

type ShotsBoxProps = {
  isHome?: boolean
  color: string
  totalPercent: number
  onGoalPercent: number
  shots: number
  onGoal: number
}
const ShotsBox = ({
  isHome,
  color,
  totalPercent,
  onGoalPercent,
  shots,
  onGoal,
}: ShotsBoxProps) => {
  const viewBoxHeight = 100
  const viewBoxWidth = 120
  const onGoalX = viewBoxWidth - viewBoxWidth * (onGoalPercent / totalPercent)
  const onGoalY = viewBoxHeight - viewBoxHeight * (onGoalPercent / totalPercent)
  const valueText = `${shots} (${onGoal})`
  return (
    <Container isHome={!!isHome}>
      <motion.div
        initial={false}
        // initial={{ width: 0 }}
        animate={{ width: `${totalPercent * 100}%` }}
      >
        <ChartValueText isHome={!!isHome}>
          {/* Part of the rtl hack to get text to overflow in correct direction */}
          {/* {isHome ? `(${onGoal}) ${shots}` : `${shots} (${onGoal})`} */}
          {isHome
            ? valueText
                .split(' ')
                .reverse()
                .join(' ')
            : valueText}
        </ChartValueText>
        {!!shots && (
          <Svg viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}>
            <rect width="100%" height="100%" rx={`${4 / totalPercent}`} />
            <rect
              width="100%"
              height="100%"
              rx={`${4 / totalPercent}`}
              fill={`${color}88`}
            />
            <motion.rect
              // x={isHome ? onGoalX : -onGoalX}
              // y={onGoalY}
              initial={false}
              animate={{
                x: isHome ? onGoalX : -onGoalX,
                y: onGoalY,
              }}
              width="100%"
              height="100%"
              rx={`${4 / totalPercent}`}
              fill={color}
            />
          </Svg>
        )}
      </motion.div>
    </Container>
  )
}

export default ShotsBox

type ContainerProps = {
  isHome: boolean
}
const Container = styled(motion.div)<ContainerProps>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 4px;
  align-items: ${(p) => (p.isHome ? 'flex-end' : 'flex-start')};
`
const Svg = styled(motion.svg)`
  display: block;
  fill: rgba(255, 255, 255, 0.45);
  stroke-width: 0;
`
