import React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { ChartValueText } from '../../styles'

type DonutProps = {
  color: string
  percent: number
  total: number
  percentOfMax: number
  isHome?: boolean
}
const Donut = ({ color, percent, total, percentOfMax, isHome }: DonutProps) => {
  const fontSize = 13 / percentOfMax
  return (
    <Container isHome={!!isHome}>
      <motion.div
        initial={false}
        // initial={{ width: 0 }}
        animate={{ width: `${100 * percentOfMax}%` }}
      >
        <ChartValueText isHome={!!isHome}>{total}</ChartValueText>
        <Svg viewBox="0 0 100 100">
          <circle cx={50} cy={50} r={radius} />
          <circle cx={50} cy={50} r={radius} stroke={`${color}55`} />
          <motion.circle
            cx={50}
            cy={50}
            r={radius}
            stroke={color}
            initial={false}
            animate={{
              strokeDasharray: `
                ${2 * Math.PI * radius * percent}
                ${2 * Math.PI * radius * (1 - percent)}`,
              strokeDashoffset: `${-2 * Math.PI * radius * 0.75}`,
            }}
          />
          <PercentText x={50} y={50} fontSize={`${fontSize}px`}>
            {percent * 100}%
          </PercentText>
        </Svg>
      </motion.div>
    </Container>
  )
}

export default Donut

const strokeWidth = 25
const radius = 50 - strokeWidth / 2

type ContainerProps = {
  isHome: boolean
}
const Container = styled(motion.div)<ContainerProps>`
  /* flex-grow: 1; */
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding: 0 4px;
  align-items: ${(p) => (p.isHome ? 'flex-end' : 'flex-start')};
  align-items: center;
`
const Svg = styled(motion.svg)`
  display: block;
  fill: transparent;
  stroke: rgba(255, 255, 255, 0.35);
  stroke-width: ${strokeWidth}px;
`
const PercentText = styled.text`
  stroke-width: 0;
  text-anchor: middle;
  dominant-baseline: middle;
  font-weight: 400;
  /* fill: ${(p) => p.theme.palette.primary.light}; */
  fill: rgba(255, 255, 255, 0.85);
`
