import { Roster, TeamColors } from '../types'
import React from 'react'
import styled from 'styled-components'
import FluidClubLogo from 'src/common/components/team-logos/fluid-club-logo'

type LogoFormationProps = {
  teamColors: TeamColors
  homeRoster: Roster
  awayRoster: Roster
}
const LogoFormation = ({
  teamColors,
  homeRoster,
  awayRoster,
}: LogoFormationProps) => {
  return (
    <Container>
      <HomeContainer teamColor={teamColors.homeColor}>
        <LogoWrapper>
          <FluidClubLogo clubName={homeRoster.team.displayName} sizes="50px" />
        </LogoWrapper>
        {homeRoster.formation && `(${homeRoster.formation})`}
      </HomeContainer>
      <AwayContainer teamColor={teamColors.awayColor}>
        {/* ({awayRoster.formation}) */}
        {awayRoster.formation && `(${awayRoster.formation})`}
        <LogoWrapper>
          <FluidClubLogo clubName={awayRoster.team.displayName} sizes="50px" />
        </LogoWrapper>
      </AwayContainer>
    </Container>
  )
}

export default LogoFormation

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: fit-content;
  min-width: 250px;
  margin: auto;
`
type TeamContainerProps = {
  teamColor: string
}
const TeamContainer = styled.div<TeamContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 3px solid ${(p) => p.teamColor};
  color: rgba(215, 215, 215, 0.8);
  font-weight: bold;
  font-size: 1rem;
  @media (min-width: 375px) {
    font-size: 1.1rem;
  }
`
const HomeContainer = styled(TeamContainer)`
  border-right: none;
  border-top-left-radius: ${(p) => p.theme.shape.borderRadius}px;
  border-bottom-left-radius: ${(p) => p.theme.shape.borderRadius}px;
  padding-right: ${(p) => p.theme.spacing(1)}px;
`
const AwayContainer = styled(TeamContainer)`
  border-left: none;
  border-top-right-radius: ${(p) => p.theme.shape.borderRadius}px;
  border-bottom-right-radius: ${(p) => p.theme.shape.borderRadius}px;
  padding-left: ${(p) => p.theme.spacing(1)}px;
`
const LogoWrapper = styled.div`
  width: 38px;
  margin: ${(p) => p.theme.spacing(1)}px;
`
