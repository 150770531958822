import React, { useState } from 'react'
import SummaryTabs from './summary-tabs'
import { SummaryCard } from '../styles'
import useSwipeNav from 'src/common/hooks/use-swipe-nav'

export const values = ['Statistics', 'Lineups', 'Commentary'] as const
export type SummaryTabValue = typeof values[number]

type TabViewProps = {
  statistics?: React.ReactElement
  lineups?: React.ReactNode
  commentary?: React.ReactNode
  isLive?: boolean
}
const TabView = ({ statistics, lineups, commentary, isLive }: TabViewProps) => {
  const [tabValue, setTabValue] = useState<typeof values[number]>('Statistics')
  const handlers = useSwipeNav(setTabValue, values)

  return (
    <SummaryCard {...{ isLive }} {...handlers}>
      <SummaryTabs {...{ tabValue, setTabValue }} />
      {tabValue === 'Statistics' && statistics}
      {tabValue === 'Lineups' && lineups}
      {tabValue === 'Commentary' && commentary}
    </SummaryCard>
  )
}

export default TabView
