import { TeamColors, Roster, RosterPlayer } from '../../types'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import MuiList from '@material-ui/core/List'
import PlayerListItem from './player-list-item'
import { getPlayerSubbedOutFor } from '../utils'
import WaitingDots from 'src/common/components/waiting-dots'
import { spacing } from 'src/common/styles'

type LineupListProps = {
  teamColors: TeamColors
  homeRoster: Roster
  awayRoster: Roster
  isSubsOnly?: boolean
}
const LineupList = ({
  teamColors,
  homeRoster,
  awayRoster,
  isSubsOnly,
}: LineupListProps) => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  const homeLineup = playerList(
    homeRoster,
    teamColors.homeColor,
    isMounted,
    !!isSubsOnly
  )
  const awayLineup = playerList(
    awayRoster,
    teamColors.awayColor,
    isMounted,
    !!isSubsOnly
  )

  if (!(homeRoster.roster || awayRoster.roster) && !isSubsOnly) {
    return <WaitingWrapper>Waiting for lineup data{WaitingDots}</WaitingWrapper>
  }

  console.log(!!(homeRoster.roster || awayRoster.roster))

  return (
    <Wrapper>
      {isSubsOnly ? (
        !!(homeLineup.length + awayLineup.length) && (
          <>
            <SubsHeading>Substitutions</SubsHeading>
            <List dense>{homeLineup}</List>
            <List dense>{awayLineup}</List>
          </>
        )
      ) : (
        <>
          <List dense>{homeLineup}</List>
          <List dense>{awayLineup}</List>
        </>
      )}
      {!!(homeRoster.roster || awayRoster.roster) && (
        <>
          <SubsHeading>Bench</SubsHeading>
          <List dense>
            {benchList(homeRoster, teamColors.homeColor, isMounted)}
          </List>
          <List dense>
            {benchList(awayRoster, teamColors.awayColor, isMounted)}
          </List>
        </>
      )}
    </Wrapper>
  )
}

export default LineupList

const WaitingWrapper = styled.div`
  padding: ${spacing(2)} ${spacing(1)};
`
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  /* grid-template-columns: 1fr 1fr; */
  font-size: 0.75rem;
  width: 100%;
`
const List = styled(MuiList)`
  /* max-width: 100%; */
  width: 100%;
  overflow: hidden;
`
const Heading = styled.div`
  grid-column: 1 / -1;
  text-transform: uppercase;
  letter-spacing: 0.1ch;
  padding: ${(p) => p.theme.spacing(1)}px;
  font-size: 1.1em;
`
const SubsHeading = styled(Heading)`
  border-bottom: 1px solid rgba(215, 215, 215, 0.35);
`

const benchList = (roster: Roster, color: string, isMounted: boolean) =>
  roster.roster?.map((player, i) => {
    if (!(player.starter || player.subbedIn)) {
      return (
        <PlayerListItem
          player={player}
          teamColor={color}
          roster={roster}
          isMounted={isMounted}
          isSub={false}
          i={i}
          key={player.athlete.id}
        />
      )
    }
    return null
  }) ?? null

const playerList = (
  roster: Roster,
  color: string,
  isMounted: boolean,
  isSubsOnly: boolean
) => {
  const starters =
    roster.roster?.filter(
      // (player) => player.starter && (isSubsOnly ? player.subbedOut : true)
      (player) => player.starter && (!isSubsOnly || player.subbedOut === true)
    ) ?? []
  const players: RosterPlayer[] = [...starters]
  const addSubbedInFor = (subbedOutPlayer: RosterPlayer) => {
    const playerSubbedOutFor = getPlayerSubbedOutFor(subbedOutPlayer, roster)
    if (playerSubbedOutFor) {
      const index = players.findIndex(
        (player) => subbedOutPlayer.athlete.id === player.athlete.id
      )
      players.splice(index + 1, 0, playerSubbedOutFor)
    }
    if (playerSubbedOutFor?.subbedOut === true) {
      addSubbedInFor(playerSubbedOutFor)
    }
  }

  starters.forEach((player) => {
    //need explicit check to avoid truthy {didSub: false}
    if (player.subbedOut === true) {
      addSubbedInFor(player)
    }
  })

  return players.map((player, i) => (
    <PlayerListItem
      player={player}
      teamColor={color}
      roster={roster}
      isMounted={isMounted}
      isSub={!player.starter}
      i={i}
      key={player.athlete.id}
    />
  ))
}
