import { TeamColors, Roster } from '../types'
import React, { useState } from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import Hidden from '@material-ui/core/Hidden'
import Formation from './formation'
import LineupList from './lineup-list'
import ViewToggle from './view-toggle'
import InfoContent from './info-popover/info-content'
import LogoFormation from './logo-formation'
import { SummaryContainer } from '../styles'

type LineupProps = {
  teamColors: TeamColors
  rosters: Roster[]
}
const Lineup = ({ teamColors, rosters }: LineupProps) => {
  const [view, setView] = useState('formation')
  const homeRoster = rosters[0]
  const awayRoster = rosters[1]

  const props = { teamColors, homeRoster, awayRoster }
  return (
    <>
      <LogoFormation {...props} />
      <Hidden smDown>
        <ViewToggle {...{ view, setView }} />
        {view === 'formation' ? (
          <Formation {...props} />
        ) : (
          <LineupList {...props} />
        )}
      </Hidden>
      <Hidden mdUp>
        <Formation {...props} />
        <LineupList {...props} isSubsOnly />
      </Hidden>
    </>
  )
}

export default React.memo(Lineup)
