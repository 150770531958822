import React, { useState, useRef, useEffect, createContext } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import useSummaryData from './use-summary-data'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Hidden from '@material-ui/core/Hidden'
import Lineup from './lineup'
import Commentary from './commentary'
import Statistics from './statistics'
import LoadingSpinner from 'src/common/components/loading-spinner'
import TopSection from './top-section'
import TabView from './tab-view'
import ScrollToTopFab from '../scroll-to-top-fab'
import useTheme from 'src/common/hooks/use-theme'
import useMotionNavOffsetStyle from 'src/common/hooks/use-motion-nav-offset-style'
import { getClubInfo } from 'src/common/clubs'
import { dodgeFab } from 'src/common/styles'
import { paperBg, SummaryContainer, SummaryCard } from './styles'
import { twoSpacing, spacing } from '../standings/styles'
import { SummaryData } from './types'
import YoutubeAudioButton from '../youtube-audio-button'
import AtlConfetti from '../atl-confetti'
// import useMatchData from 'src/common/hooks/use-match-data'

type SummaryProps = {
  isLive?: boolean
  data: SummaryData
}
const Summary = ({ isLive, data }: SummaryProps) => {
  const theme = useTheme()
  const props = useMotionNavOffsetStyle({ cssProp: 'padding' })

  const isHome = data.boxscore.teams[1].team.displayName === 'Atlanta United FC'

  const stats = data.boxscore?.teams.map((team) => team.statistics)
  const colors = data.boxscore?.teams.map((team) => `#${team.team.color}`)
  const oppColor = colors ? colors[isHome ? 0 : 1] : theme.palette.grey[300]
  const atlColor = theme.palette.secondary.main
  const homeColor = isHome ? atlColor : oppColor
  const awayColor = isHome ? oppColor : atlColor
  const teamColors = {
    atlColor,
    oppColor,
    homeColor,
    awayColor,
  }

  const homeTeam = data.header.competitions[0].competitors[0]
  const awayTeam = data.header.competitions[0].competitors[1]
  const competition = data.header.competitions[0]
  const keyEvents = data.keyEvents

  const isMatchPre = competition.status.type.state === 'pre'

  const statistics = (
    <Statistics
      date={data.date}
      homeName={homeTeam.team.name ?? ''}
      awayName={awayTeam.team.name ?? ''}
      {...{ teamColors, isHome, stats }}
    />
  )
  const lineups = data.rosters && (
    <Lineup teamColors={teamColors} rosters={data.rosters} />
  )
  const commentary = (
    <Commentary
      commentary={data.commentary ?? []}
      {...{ teamColors, isLive }}
    />
  )

  return (
    <>
      <PageContainer {...props}>
        <PageGrid>
          {homeTeam && awayTeam && competition && (
            <TopSection
              {...{
                homeTeam,
                awayTeam,
                teamColors,
                competition,
                keyEvents,
                isLive,
              }}
            />
          )}
          <Hidden mdUp>
            <TabView {...{ statistics, lineups, commentary, isLive }} />
          </Hidden>
          <Hidden smDown>
            <SummaryCard {...{ isLive }}>{commentary}</SummaryCard>
            <StatLineupContainer>
              <SummaryCard {...{ isLive }}>{lineups}</SummaryCard>
              {/* <SummaryCard isLive={isLive}>
                this is where lineups would be
              </SummaryCard> */}
              <SummaryCard {...{ isLive }}>{statistics}</SummaryCard>
            </StatLineupContainer>
          </Hidden>
        </PageGrid>
      </PageContainer>

      {/* <ScrollToTopFab /> */}
      {isLive ? (
        <>
          <YoutubeAudioButton
            videoId="PGQO1YvluU4"
            playerVars={isMatchPre ? { end: 55 } : { end: 55, autoplay: 0 }}
          />
          {isMatchPre && <AtlConfetti />}
        </>
      ) : (
        <ScrollToTopFab />
      )}
    </>
  )
}

export default React.memo(Summary)

const PageContainer = styled(motion.div)`
  color: rgba(255, 255, 255, 0.85);
  /* margin-left: calc(100vw - 100%); */
  ${dodgeFab()}
  ${(p) => p.theme.breakpoints.up('md')} {
    margin-left: 0;
  }
`
const PageGrid = styled.div`
  display: grid;
  padding-bottom: 0;
  align-items: start;
  grid-gap: ${spacing(1)};
  margin: auto;
  @media (min-width: 375px) {
    padding: ${spacing(1)};
  }
  @media (min-width: 700px) {
    width: 700px;
  }
  ${(p) => p.theme.breakpoints.up('md')} {
    width: initial;
    grid-template-columns: minmax(330px, 1.5fr) minmax(360px, 1fr);
    padding: ${spacing(1)} ${spacing(2)} 0 ${spacing(2)};
    /* padding-bottom: 0; */
  }
  ${(p) => p.theme.breakpoints.up('lg')} {
    grid-template-columns: minmax(600px, 1fr) minmax(360px, 1.4fr);
  }
`
const StatLineupContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(335px, 1fr));
  grid-gap: ${spacing(1)};
  height: fit-content;
`
