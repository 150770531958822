import { KeyEvent } from '../types'
import React from 'react'
import styled, { FlattenSimpleInterpolation } from 'styled-components'
import { goalIconCss, redCardIconCss } from '../play-icon'

type TeamEventProps = {
  events: KeyEvent[]
  isHome?: boolean
}
const TeamEvent = ({ events, isHome }: TeamEventProps) => {
  return (
    <Container isHome={isHome} isGoal={events[0].scoringPlay}>
      {events.map((event, i) => {
        const name = event.participants[0].athlete.displayName
          .split(' ')
          .map((part, i) => (i === 0 ? part.charAt(0).concat('.') : part))
          .join(' ')

        return (
          <PlayerContainer isHome={isHome} key={event.id}>
            {name}
            <TimeWrapper>
              ({event.clock.displayValue}
              {event.type.text === 'Own Goal' && ' OG'})
            </TimeWrapper>
            {/* {i < events.length - 1 && ','} */}
          </PlayerContainer>
        )
      })}
    </Container>
  )
}

export default TeamEvent

type ContainerProps = {
  isHome?: boolean
  isGoal: boolean
}
const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(p) => (p.isHome ? 'flex-end' : 'flex-start')};
  ${(p) => (p.isHome ? `margin-right: 2ch;` : `margin-left: 2ch;`)};
  ::before {
    position: absolute;
    top: -0.1em;
    font-size: 1.2em;
    ${(p) => (p.isHome ? `right: -1ch;` : `left: -1ch;`)}
    ${(p) => (p.isGoal ? goalIconCss : redCardIconCss)}
  }
`
type PlayerContainerProps = {
  isHome?: boolean
}
const PlayerContainer = styled.div<PlayerContainerProps>`
  padding: 0 8px;
  text-align: ${(p) => (p.isHome ? 'right' : 'left')};
`
const TimeWrapper = styled.span`
  color: rgba(255, 255, 255, 0.6);
  font-weight: normal;
  padding-left: 4px;
  font-family: 'Roboto';
`
