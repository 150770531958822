import { StatValues, TeamColors } from '../../types'
import React, { useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion'
import styled from 'styled-components'
import ShotsBox from './shots-box'
import { ChartLabel } from '../../styles'

type ShotsProps = {
  statValues: StatValues[]
  teamColors: TeamColors
}
const Shots = ({ statValues, teamColors }: ShotsProps) => {
  const homeOnGoal = statValues[1].homeVal ?? 0
  // const homeOnGoal = 0
  const awayOnGoal = statValues[1].awayVal ?? 0
  const homeShots = statValues[0].homeVal ?? 0
  // const homeShots = 0
  const awayShots = statValues[0].awayVal ?? 0
  const maxShots = Math.max(homeShots, awayShots)
  const homeTotalPercent = maxShots > 0 ? homeShots / maxShots : 0
  const awayTotalPercent = maxShots > 0 ? awayShots / maxShots : 0
  const homeOnGoalPercent = maxShots > 0 ? homeOnGoal / maxShots : 0
  const awayOnGoalPercent = maxShots > 0 ? awayOnGoal / maxShots : 0

  return (
    <Container>
      <ChartLabel>Shots (On Goal)</ChartLabel>
      <BoxContainer>
        <ShotsBox
          color={teamColors.homeColor}
          // totalPercent={0}
          // onGoalPercent={0}
          // shots={0}
          // onGoal={0}
          totalPercent={homeTotalPercent}
          onGoalPercent={homeOnGoalPercent}
          shots={homeShots}
          onGoal={homeOnGoal}
          isHome
        />
        <ShotsBox
          color={teamColors.awayColor}
          totalPercent={awayTotalPercent}
          onGoalPercent={awayOnGoalPercent}
          shots={awayShots}
          onGoal={awayOnGoal}
        />
      </BoxContainer>
    </Container>
  )
}

export default Shots

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  /* height: fit-content; */
  width: 100%;
  max-width: 250px;
`
const BoxContainer = styled.div`
  display: flex;
  width: fit-content;
  width: 100%;
  height: 100%;
  justify-content: center;
`
