import React from 'react'
import styled from 'styled-components'

type AtlTextProps = {
  text: string
}
const AtlText = ({ text }: AtlTextProps) =>
  text.split('').map((letter, i) => {
    if (i % 2 > 0) return <Black key={i}>{letter}</Black>
    return <Red key={i}>{letter}</Red>
  })

export default AtlText

const Red = styled.span`
  color: ${(p) => p.theme.palette.primary.light};
  background-color: ${(p) => p.theme.palette.secondary.main};
`
const Black = styled.span`
  color: ${(p) => p.theme.palette.primary.light};
  background-color: ${(p) => p.theme.palette.background.default};
`
