import { useStaticQuery, graphql } from 'gatsby'
import { ClubLogosQuery } from 'types/graphql-types'

const useClubLogos = () => {
  const { allFile } = useStaticQuery<ClubLogosQuery>(graphql`
    query ClubLogos {
      allFile(
        filter: {
          relativeDirectory: { eq: "logos" }
          sourceInstanceName: { eq: "images" }
        }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 300, quality: 1) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  const logoData = allFile.nodes

  return logoData
}

export default useClubLogos
