import { TeamColors, Team, Competition, Competitor, KeyEvent } from '../types'
import React from 'react'
import { motion, useViewportScroll } from 'framer-motion'
import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'
// import useScrollTrigger from '@material-ui/core/useScrollTrigger'
// import useMediaQuery from '@material-ui/core/useMediaQuery'
// import useTheme from 'src/common/hooks/use-theme'
import WaitingDots from 'src/common/components/waiting-dots'
import { paperBg, SummaryCard } from '../styles'
import { glassyDefaultBG } from 'src/common/styles'
import TopSectionTeam from './top-section-team'
import KeyPlays from './key-plays'
import { paperStyles } from './styles'
import { spacing } from 'src/features/standings/styles'

type TopSectionProps = {
  teamColors: TeamColors
  homeTeam: Competitor
  awayTeam: Competitor
  competition: Competition
  keyEvents?: KeyEvent[]
  isLive?: boolean
}
const TopSection = ({
  teamColors,
  homeTeam,
  awayTeam,
  competition,
  keyEvents,
  isLive,
}: TopSectionProps) => {
  // const theme = useTheme()
  // const mdMobileUp = useMediaQuery('(min-width: 375px)')
  // const mdUp = useMediaQuery(theme.breakpoints.up('md'))
  // const spacing = theme.spacing(mdUp ? 2 : mdMobileUp ? 1 : 0)
  // const trigger = useScrollTrigger({
  //   disableHysteresis: true,
  //   threshold: spacing,
  // })
  // const isScrolled = useScrollTrigger({
  //   disableHysteresis: true,
  //   threshold: 0,
  // })

  const isMatchPre = competition.status.type.state === 'pre'
  const isMatchLive = competition.status.type.state === 'in'

  return (
    <Container {...{ isLive }}>
      <TopContainer
        style={
          {
            // marginLeft: trigger ? '0px' : `${spacing}px`,
            // marginRight: trigger ? '0px' : `${spacing}px`,
            // borderRadius: trigger ? '0px' : `${theme.shape.borderRadius}px`,
          }
        }
      >
        <TopSectionTeam team={homeTeam} isHome />
        <ScoreContainer isWinner={homeTeam.winner} isHome>
          {isMatchPre ? '' : homeTeam.score}
        </ScoreContainer>
        <TimeContainer
          style={{ marginTop: isMatchLive || isMatchPre ? '3px' : '0' }}
        >
          <Time>
            {isMatchPre ? (
              <span style={{ textAlign: 'center', width: '100%' }}>
                Starting soon
              </span>
            ) : (
              competition.status.type.shortDetail
            )}
          </Time>
          {/* {isMatchPre && <Time>Starting soon{WaitingDots}</Time>} */}
          {(isMatchLive || isMatchPre) && (
            <LiveLine
              animate={{ x: ['-110%', '110%'] }}
              transition={{
                loop: Infinity,
                duration: 2,
                repeatDelay: 0.6,
                ease: 'easeInOut',
              }}
            />
          )}
        </TimeContainer>
        <ScoreContainer isWinner={awayTeam.winner}>
          {isMatchPre ? '' : awayTeam.score}
        </ScoreContainer>
        <TopSectionTeam team={awayTeam} />
      </TopContainer>

      {keyEvents && (
        <KeyPlays
          homeId={homeTeam.id}
          awayId={awayTeam.id}
          {...{ keyEvents }}
        />
      )}
    </Container>
  )
}

export default TopSection

const Container = styled(SummaryCard)`
  font-size: 1.3rem;
  ${(p) => p.theme.breakpoints.up('sm')} {
    font-size: 2.1em;
  }
  ${(p) => p.theme.breakpoints.up('md')} {
    grid-column: 1 / -1;
  }
`
const TopContainer = styled.div`
  display: grid;
  grid-template-columns: 25% 2ch auto 2ch 25%;
  align-items: center;
  justify-items: center;
  margin-bottom: ${(p) => p.theme.spacing(1)}px;
  ${(p) => p.theme.breakpoints.up('md')} {
    grid-template-columns: 35% 2ch auto 2ch 35%;
  }
`
const KeyPlaysContainer = styled(motion.div)`
  overflow: hidden;
`
const TimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  font-size: 0.8em;
  line-height: 1.05;
  overflow: hidden;
`
const Time = styled.span`
  padding: 0 8px;
`
const LiveLine = styled(motion.div)`
  width: 100%;
  height: 3px;
  background-color: ${(p) => p.theme.palette.secondary.main};
`
type ScoreContainerProps = {
  isWinner: boolean
  isHome?: boolean
}
const ScoreContainer = styled.div<ScoreContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  ::before {
    display: block;
    position: absolute;
    font-family: 'ESPN-Icon-Font';
    /* Great Jorb! */
    margin-top: 0.05em;
    font-size: 0.6em;
    left: ${(p) => (p.isHome ? '1.3ch' : '-1.3ch')};
    content: ${(p) =>
      p.isWinner ? (p.isHome ? "'\\E087'" : "'\\E088'") : undefined};
  }
`
