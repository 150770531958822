import React from 'react'
import styled from 'styled-components'
import Avatar from '@material-ui/core/Avatar'

type HeadshotProps = {
  src?: string
  alt?: string
  color: string
}
const Headshot = ({ src, alt, color }: HeadshotProps) => {
  return (
    <HeadshotContainer>
      {src ? (
        <Img src={src} alt={alt} />
      ) : (
        <PlaceholderHeadshot style={{ color }} />
      )}
    </HeadshotContainer>
  )
}

export default Headshot

const HeadshotContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  align-self: end;
  position: relative;
  width: 75px;
  flex: 1 1 75px;
  height: 100px;
  overflow: hidden;
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  grid-row: 1 / -1;
`
const Img = styled.img`
  height: 100%;
`
const PlaceholderHeadshot = styled(Avatar)`
  width: 165%;
  height: 165%;
  position: absolute;
  top: -20%;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: rgba(0, 0, 0, 0);
`
