import { RosterPlayer, RosterTeam, Roster } from '../../types'
import React, { useState } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Icon from '@material-ui/core/Icon'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import Tooltip from '@material-ui/core/Tooltip'
import InfoPopover from '../info-popover'
import PlayIcon from '../../play-icon'
import { getGoalCardStats, getPlayerSubTime } from '../utils'
import { spacing } from 'src/features/standings/styles'

type PlayerListProps = {
  teamColor: string
  player: RosterPlayer
  // team: RosterTeam
  roster: Roster
  isSub: boolean
  isMounted: boolean
  i: number
}
const PlayerListItem = ({
  teamColor,
  player,
  roster,
  isSub,
  isMounted,
  i,
}: PlayerListProps) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  // const subTime = player.plays?.find((play) => play.substitution)?.clock
  //   .displayValue
  const subTime = getPlayerSubTime(player)

  const openTooltip = () => {
    setIsTooltipOpen(true)
  }
  const closeTooltip = () => {
    setIsTooltipOpen(false)
  }

  return (
    <>
      <Container
        initial={isMounted && { height: 0 }}
        animate={{ height: 'auto' }}
        transition={{ type: 'tween', duration: 0.25, delay: 0.25 * i }}
        positionTransition
      >
        <Player>
          {isSub && (
            <SubbedIconWrapper>
              <Tooltip
                title={subTime ?? ''}
                placement="right"
                open={isTooltipOpen}
                onOpen={openTooltip}
                onClose={closeTooltip}
                onClick={openTooltip}
              >
                <span>
                  <PlayIcon text="Substitution" />
                </span>
              </Tooltip>
            </SubbedIconWrapper>
          )}
          <InfoPopover player={player} color={teamColor} roster={roster}>
            <PlayerItem button>
              <PlayerNumberIcon>
                <Icon>
                  <Svg viewBox="0 0 24 24">
                    <circle cx="12" cy="12" r="12" fill={teamColor} />
                    <NumberText x="12" y="16.5" teamColor={teamColor}>
                      {player.jersey}
                    </NumberText>
                  </Svg>
                </Icon>
              </PlayerNumberIcon>
              <PlayerText>
                <PositionText>{player.athlete.role}</PositionText>
                <PlayerName>
                  <FirstInitial>
                    {player.athlete.displayName.split(' ')[0].charAt(0)}.
                  </FirstInitial>
                  {player.athlete.lastName ||
                    player.athlete.displayName.split(' ').reverse()[0]}
                </PlayerName>
                {player.stats &&
                  getGoalCardStats(player.stats).map((stat, i) => (
                    <LineupPlayIcon
                      initial={isMounted && { scale: 0 }}
                      animate={{ scale: 1 }}
                      key={i}
                    >
                      <PlayIcon text={stat.displayName} />
                    </LineupPlayIcon>
                  ))}
              </PlayerText>
            </PlayerItem>
          </InfoPopover>
        </Player>
      </Container>
    </>
  )
}

export default PlayerListItem

const Container = styled(motion.div)`
  overflow: hidden;
  /* width: 100%; */
`
const Player = styled.div`
  display: flex;
  align-items: center;
`
const SubbedIconWrapper = styled.div`
  flex-shrink: 0;
  height: 24px;
  width: 24px;
  line-height: 24px;
  text-align: center;
  margin-right: 2px;
`
type PlayerItemProps = {
  button?: any
}
const PlayerItem = styled(ListItem)<PlayerItemProps>`
  height: 28px;
  border-radius: 14px;
  padding: 0;
  :active {
    background-color: rgba(255, 255, 255, 0.1);
  }
`
const PlayerText = styled(ListItemText)`
  padding-left: 4px;
  span {
    font-size: 1em;
    display: flex;
    flex-wrap: wrap;
  }
`
const PositionText = styled.span`
  opacity: 0.7;
  color: ${(p) => p.theme.palette.primary.main};
  padding-right: ${(p) => p.theme.spacing(1)}px;
`
const PlayerName = styled.span`
  /* display: flex;
  padding-right: 4px; */
`
const FirstInitial = styled.span`
  margin-right: 2px;
  opacity: 0.85;
`
const PlayerNumberIcon = styled(ListItemIcon)`
  padding: 0;
  min-width: 0;
`
const Svg = styled.svg`
  display: block;
  stroke-width: 0;
`
type NumberTextProps = {
  teamColor: string
}
const NumberText = styled.text<NumberTextProps>`
  fill: ${(p) => p.theme.palette.getContrastText(p.teamColor)};
  font-size: 12px;
  stroke-width: 0;
  text-anchor: middle;
  font-weight: 700;
`
const LineupPlayIcon = styled(motion.span)`
  padding-left: 4px;
`
