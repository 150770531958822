import { KeyEvent, Competitor } from '../types'
import React from 'react'
import { motion, useViewportScroll } from 'framer-motion'
import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'
import TeamEvent from './team-event'
import { paperStyles } from './styles'
import { goalIconCss, redCardIconCss } from '../play-icon'

type KeyPlaysProps = {
  keyEvents: KeyEvent[]
  homeId: string
  awayId: string
}
const KeyPlays = ({ keyEvents, homeId, awayId }: KeyPlaysProps) => {
  const homeGoalEvents = keyEvents.filter(
    (event) => event.scoringPlay && event.team.id === homeId
  )
  const awayGoalEvents = keyEvents.filter(
    (event) => event.scoringPlay && event.team.id === awayId
  )
  const homeCardEvents = keyEvents.filter(
    (event) => event.type.text === 'Red Card' && event.team.id === homeId
  )
  const awayCardEvents = keyEvents.filter(
    (event) => event.type.text === 'Red Card' && event.team.id === awayId
  )

  const homeEvents = [homeGoalEvents, homeCardEvents]
  const awayEvents = [awayGoalEvents, awayCardEvents]

  return (
    <Container>
      <TeamContainer isHome>
        {homeEvents.map((event, i) =>
          event.length ? (
            <TeamEvent events={event} isHome key={`${i}-home`} />
          ) : null
        )}
      </TeamContainer>
      <TeamContainer>
        {awayEvents.map((event, i) =>
          event.length ? <TeamEvent events={event} key={`${i}-away`} /> : null
        )}
      </TeamContainer>
    </Container>
  )
}

export default KeyPlays

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${(p) => p.theme.spacing(6)}px;
  font-size: 0.5em;
  color: rgba(255, 255, 255, 0.7);
  font-weight: bold;
  /* @media (min-width: 375px) {
    column-gap: ${(p) => p.theme.spacing(4)}px;
  } */
  ${(p) => p.theme.breakpoints.up('md')} {
    justify-items: center;
    max-width: 1000px;
    margin: auto;
  }
`
type TeamContainerProps = {
  isHome?: boolean
}
const TeamContainer = styled.div<TeamContainerProps>`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 4px;
  ${(p) => p.theme.breakpoints.up('md')} {
    width: fit-content;
  }
  /* grid-column: ${(p) => `${p.isHome ? '1' : '4'} / span 2`}; */
`
