import { SetState } from 'src/common/types'
import React from 'react'
import styled from 'styled-components'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { SummaryTabValue } from '.'

type SummaryTabsProps = {
  tabValue: SummaryTabValue
  setTabValue: SetState<SummaryTabValue>
}
const SummaryTabs = ({ tabValue, setTabValue }: SummaryTabsProps) => {
  const handleChange = (e: React.ChangeEvent<{}>, value: SummaryTabValue) => {
    setTabValue(value)
  }

  return (
    <Container>
      <Tabs value={tabValue} onChange={handleChange} variant="fullWidth">
        <Tab label="Statistics" value="Statistics" />
        <Tab label="Lineups" value="Lineups" />
        <Tab label="Commentary" value="Commentary" />
      </Tabs>
    </Container>
  )
}

export default SummaryTabs

const Container = styled.div`
  margin-bottom: ${(p) => p.theme.spacing(1)}px;
  color: ${(p) => p.theme.palette.primary.light};
  .Mui-selected {
    color: ${(p) => p.theme.palette.lightGold};
  }
`
