import { Stat } from './types'
import React from 'react'
import styled from 'styled-components'
import StatIcons from '../../stat-icons'

type InfoStatsItemProps = {
  caption: string
  stats: Stat[]
}
const InfoStatsItem = ({ caption, stats }: InfoStatsItemProps) => (
  <Tbody>
    <tr>
      <Caption>{caption}</Caption>
    </tr>
    {stats.map(
      (stat) =>
        stat.label && (
          <tr key={stat.label}>
            <Label>{stat.label}</Label>
            <td>
              <StatIcons value={stat.value} iconName={stat.iconName} />
            </td>
            <Value>{stat.value}</Value>
          </tr>
        )
    )}
  </Tbody>
)

export default InfoStatsItem
const Tbody = styled.tbody`
  tr:nth-of-type(2) {
    border-top: 2px solid rgba(215, 215, 215, 0.15);
  }
  tr:nth-of-type(2n + 3) {
    background-color: ${(p) => p.theme.palette.background.default}88;
  }
`
const Caption = styled.td`
  padding-top: ${(p) => p.theme.spacing(1)}px;
  font-size: 1.15em;
  letter-spacing: 0.05ch;
`
const Label = styled.td`
  opacity: 0.9;
  font-size: 0.95em;
  font-weight: normal;
  white-space: nowrap;
  color: ${(p) => p.theme.palette.lightGold};
`
const Value = styled.td`
  text-align: right;
  width: 2ch;
  color: ${(p) => p.theme.palette.primary.light};
`
