import { StatValues, TeamColors } from '../../types'
import React from 'react'
import styled from 'styled-components'
import StatIcons from '../../stat-icons'

type StatTableProps = {
  statValues: StatValues[]
  teamColors: TeamColors
}
const StatTable = ({ statValues, teamColors }: StatTableProps) => (
  <Container>
    <Table>
      <tbody>
        {statValues.map((value) => (
          <tr key={value.label}>
            <ValueTd>{value.homeVal}</ValueTd>
            <td>
              <StatIcons
                value={value.homeVal ?? 0}
                iconName={value.label}
                direction="right"
              />
            </td>
            <Label>{value.label}</Label>
            <td>
              <StatIcons value={value.awayVal ?? 0} iconName={value.label} />
            </td>
            <AwayTd>{value.awayVal}</AwayTd>
          </tr>
        ))}
      </tbody>
    </Table>
  </Container>
)

export default StatTable

const Container = styled.div`
  text-align: center;
`
const Table = styled.table`
  text-align: left;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  tr {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    td {
      padding: ${(p) => p.theme.spacing(1)}px 0;
    }
  }
  tr:last-child {
    border: none;
  }
`
const Label = styled.td`
  text-align: center;
  color: ${(p) => p.theme.palette.lightGold};
`
const ValueTd = styled.td`
  /* color: ${(p) => p.theme.palette.primary.light}; */
  color: ${(p) => p.theme.palette.lightGold};
  width: 3ch;
`
const AwayTd = styled(ValueTd)`
  text-align: right;
`
