import React from 'react'
import styled from 'styled-components'
import Confetti from 'react-confetti'
import useWindowSize from '@react-hook/window-size'
import useTheme from 'src/common/hooks/use-theme'
// import useWindowDimensions from './countdown/use-window-dimensions'

const AtlConfetti = () => {
  // const { windowWidth, windowHeight } = useWindowDimensions()
  const [windowWidth, windowHeight] = useWindowSize()
  const theme = useTheme()

  return (
    <Container>
      <Confetti
        width={windowWidth}
        height={windowHeight}
        numberOfPieces={500}
        recycle={false}
        colors={[
          theme.palette.primary.main,
          theme.palette.secondary.main,
          theme.palette.background.default,
        ]}
      />
    </Container>
  )
}

export default AtlConfetti

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
`
