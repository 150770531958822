import { StatData, TeamColors, StatValues } from '../types'
import React from 'react'
import styled from 'styled-components'
import { SummaryContainer } from '../styles'
import Typography from '@material-ui/core/Typography'
import Possesion from './possesion'
import Shots from './shots'
import StatTable from './stat-table'
import Passing from './passing'
import FluidClubLogo from 'src/common/components/team-logos/fluid-club-logo'
import { spacing } from 'src/common/styles'
import { getClubInfo } from 'src/common/clubs'

type StatisticsProps = {
  stats: StatData[][]
  date: Date
  isHome: boolean
  teamColors: TeamColors
  homeName: string
  awayName: string
}
const Statistics = ({
  stats,
  date,
  isHome,
  teamColors,
  homeName,
  awayName,
}: StatisticsProps) => {
  const statValuesArr: StatValues[] = stats[0].map((__, i) => ({
    atlVal: Number(stats[isHome ? 1 : 0][i].displayValue),
    oppVal: Number(stats[isHome ? 0 : 1][i].displayValue),
    homeVal: Number(stats[1][i].displayValue),
    awayVal: Number(stats[0][i].displayValue),
    combinedVal:
      Number(stats[0][i].displayValue) + Number(stats[1][i].displayValue),
    label: stats[0][i].label,
    name: stats[0][i].name,
  }))

  return (
    // <StatContainer>
    <>
      {statValuesArr.length > 6 ? (
        <>
          <Possesion
            statValues={statValuesArr[6]}
            {...{ teamColors, homeName, awayName }}
          />
          <ShotsPassingContainer>
            <Passing
              teamColors={teamColors}
              statValues={[statValuesArr[9], statValuesArr[10]]}
            />
            <Separator />
            <Shots
              statValues={[statValuesArr[7], statValuesArr[8]]}
              teamColors={teamColors}
            />
          </ShotsPassingContainer>
        </>
      ) : (
        <SeasonStatsContainer>
          <LogoWrapper>
            <FluidClubLogo clubName={homeName} sizes="50px" />
          </LogoWrapper>
          <div>
            <Typography variant="h5" component="h4">
              Season Stats
            </Typography>
          </div>
          <LogoWrapper>
            <FluidClubLogo clubName={awayName} sizes="50px" />
          </LogoWrapper>
        </SeasonStatsContainer>
      )}
      <StatTable
        // statValues={ statValuesArr.slice(0, 6)}
        statValues={statValuesArr.slice(0, statValuesArr.length > 6 ? 6 : 4)}
        teamColors={teamColors}
      />
    </>
    // </StatContainer>
  )
}

export default React.memo(Statistics)

const StatContainer = styled(SummaryContainer)`
  /* display: flex;
  flex-wrap: wrap; */
`
const SeasonStatsContainer = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  grid-gap: ${spacing(1)};
  align-items: center;
  justify-items: center;
  padding-bottom: ${spacing(1)};
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`
const ShotsPassingContainer = styled.div`
  display: grid;
  grid-template-columns: calc(50% - 1px) 2px calc(50% - 1px);
  /* grid-template-columns: 1fr 2px 1fr; */
  align-items: center;
  justify-items: center;
  /* min-height: 100px; */
  /* column-gap: ${(p) => p.theme.spacing(1)}px; */
  border-top: 2px solid rgba(255, 255, 255, 0.5);
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  padding: ${(p) => p.theme.spacing(2)}px 0;
`

const Separator = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  height: 85%;
  height: 100%;
  width: 100%;
`
const LogoWrapper = styled.div`
  width: 40px;
`
