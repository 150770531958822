import styled, { css } from 'styled-components'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { spacing } from '../standings/styles'

export const yellowCardColor = '#f6c825'
export const redCardColor = '#dd0000'

export const paperBg = css`
  background-color: ${(p) => p.theme.palette.background.default}dd;
`
type SummaryCardProps = {
  isLive?: boolean
}
export const SummaryCard = styled.div<SummaryCardProps>`
  box-shadow: ${(p) => p.theme.shadows[8]};
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  padding: ${spacing(1)};
  height: fit-content;
  grid-column: 1 / -1;
  background-color: ${(p) =>
    p.isLive
      ? `${p.theme.palette.background.default}dd`
      : `${p.theme.palette.background.paper}cc`};
  ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${spacing(2)};
    grid-column: initial;
  }
`

export const SummaryContainer = styled(Paper)`
  min-width: 100%;
  /* width: fit-content; */
  /* margin: auto; */
  height: fit-content;
  margin-top: ${(p) => p.theme.spacing(2)}px;
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
  /* padding: 12px 12px 12px 12px; */
  /* padding: 5px 12px 12px 12px; */
  padding: ${(p) => p.theme.spacing(1)}px;
  color: ${(p) => p.theme.palette.primary.light};
  /* color: ${(p) => p.theme.palette.lightGold}; */
  /* color: rgba(255, 255, 255, 0.9); */
  font-weight: 400;
  ${paperBg}
  /* background-color: ${(p) => p.theme.palette.background.default}; */
  ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${(p) => p.theme.spacing(2)}px;
    /* max-width: ${(p) => p.theme.breakpoints.values.sm}px; */
    /* max-width: ${(p) => p.theme.breakpoints.values.lg}px; */
    min-width: 375px;
    /* min-width: 320px; */
    margin: ${(p) => p.theme.spacing(2)}px;
    
    flex: 5 5;
    /* position: sticky; */
    top: 0;
    top: ${(p) => p.theme.spacing(2)}px;
  }
`

export const CommentaryList = styled.ul`
  width: fit-content;
  list-style: none;
  margin: 0;
  padding: 0;
`

export const ChartLabel = styled(Typography)`
  text-align: center;
  text-transform: uppercase;
  font-size: 0.875rem;
  line-height: 1em;
  letter-spacing: 0.15ch;
  /* padding-bottom: 2px; */
  padding-bottom: ${(p) => p.theme.spacing(1)}px;
  /* color: rgba(255, 255, 255, 0.9); */
  /* color: #fff; */
`
type ChartValueTextProps = {
  isHome: boolean
}
export const ChartValueText = styled.div<ChartValueTextProps>`
  /*hack to get text to overflow in correct direction*/
  direction: ${(p) => (p.isHome ? 'rtl' : 'ltr')};
  text-align: center;
  white-space: nowrap;
`
