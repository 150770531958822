import { RosterPlayer, RosterTeam, Roster } from '../../types'
import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import styled from 'styled-components'
import {
  bindToggle,
  bindTrigger,
  bindPopover,
  bindPopper,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import Popper from '@material-ui/core/Popper'
import Popover from '@material-ui/core/Popover'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import InfoContent from './info-content'
import Fade from '@material-ui/core/Fade'

type InfoCardProps = {
  player: RosterPlayer
  color: string
  // team: RosterTeam
  roster: Roster
  children: React.ReactElement
}
const InfoPopover = ({ player, color, roster, children }: InfoCardProps) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: player.athlete.id,
  })
  return (
    <>
      {React.cloneElement(children, { ...bindTrigger(popupState) })}
      {/* <Popper placement="bottom" {...bindPopper(popupState)} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={250}>
            <div>
              <ClickAwayListener onClickAway={popupState.close}>
                <div>
                  <InfoContent player={player} teamColor={color} />
                </div>
              </ClickAwayListener>
            </div>
          </Fade>
        )}
      </Popper> */}
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        {...bindPopover(popupState)}
      >
        <InfoContent player={player} teamColor={color} roster={roster} />
      </Popover>
    </>
  )
}

export default InfoPopover
