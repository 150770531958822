import { RosterPlayerStat, RosterPlayer, Roster } from '../types'

export const getGoalCardStats = (stats: RosterPlayerStat[]) => {
  const filteredStats = stats.filter(
    (stat) =>
      (stat.abbreviation === 'G' ||
        stat.abbreviation === 'OG' ||
        stat.abbreviation === 'YC' ||
        stat.abbreviation === 'RC') &&
      stat.value
  )
  filteredStats.sort((a, b) => {
    const aVal = getValue(a.abbreviation)
    const bVal = getValue(b.abbreviation)

    return aVal - bVal
  })

  return filteredStats.flatMap((stat) =>
    Array.from({ length: stat.value }, () => stat)
  )
}

const getValue = (abbrev: string) =>
  abbrev === 'G' ? 0 : abbrev === 'OG' ? 1 : abbrev === 'RC' ? 2 : 3

export const getPlayerSubbedOutFor = (
  subbedOutPlayer: RosterPlayer,
  roster: Roster
): RosterPlayer | undefined =>
  roster.roster?.find(
    (player) => player.athlete.id === subbedOutPlayer.subbedOutFor?.athlete.id
  )

export const getPlayerSubTime = (player: RosterPlayer) =>
  player.plays?.find((play) => play.substitution)?.clock.displayValue
