import { CommentaryData, TeamColors } from '../types'
import React from 'react'
import styled from 'styled-components'
import Divider from '@material-ui/core/Divider'
import AtlText from '../../atl-text'
import PlayIcon from '../play-icon'
import { getClubInfo } from 'src/common/clubs'

type CommentaryItemProps = {
  data: CommentaryData[number]
  isLastItem: boolean
  teamColors: TeamColors
}
const CommentaryItem = ({
  data,
  isLastItem,
  teamColors,
}: CommentaryItemProps) => {
  const { text, time, play } = data
  const team = play?.team?.displayName
  const isAtlanta = !!team
    ? getClubInfo(team).displayName === 'Atlanta United FC'
    : text.includes('(Atlanta United FC)')
  const playText = play?.type.text
  const isGoal =
    !!playText?.toLowerCase().includes('goal') || text.includes('Goal!')
  const teamColor = isAtlanta ? teamColors.atlColor : teamColors.oppColor

  const displayText =
    isGoal && isAtlanta
      ? [AtlText({ text: 'Goal!' }), text.replace('Goal!', '')]
      : text

  return (
    <>
      <ItemGrid>
        <TimeIconContainer {...{ isGoal, teamColor }}>
          <Time {...{ isGoal, teamColor }}>{time.displayValue || '-'}</Time>
          <Icon>
            <PlayIcon text={playText ?? text} />
            {/* <PlayIcon text={text} /> */}
          </Icon>
        </TimeIconContainer>
        <TextContainer>{displayText}</TextContainer>
      </ItemGrid>
      {!isLastItem && <ListDivider />}
    </>
  )
}

export default CommentaryItem

const ItemGrid = styled.div`
  display: grid;
  /* grid-template-columns: 45px 40px auto; */
  grid-template-columns: min-content auto;
`
type GoalProp = {
  isGoal: boolean
  teamColor: string
}
const TimeIconContainer = styled.div<GoalProp>`
  border: 2px solid
    ${(p) => (p.isGoal ? p.teamColor : p.theme.palette.primary.main)};
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  overflow: hidden;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 40.5px 1fr;
  width: 76.5px;
  height: 31.5px;
  font-size: 0.9em;
  ${(p) => p.theme.breakpoints.up('sm')} {
    grid-template-columns: 45px 1fr;
    width: 85px;
    height: 35px;
    font-size: 1em;
  }
`
const Time = styled.div<GoalProp>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-family: 'Roboto';
  font-weight: bold;
  /* color: rgba(255, 255, 255, 0.9); */
  background-color: ${(p) =>
    p.isGoal ? p.teamColor : p.theme.palette.primary.main};
  color: ${(p) =>
    p.isGoal
      ? p.theme.palette.getContrastText(p.teamColor)
      : 'rgba(255, 255, 255, 0.85)'};
`
const Icon = styled.div`
  display: flex;
  font-size: 1.35em;
`
const TextContainer = styled.div`
  opacity: 0.9;
  padding-left: 8px;
  padding-right: 8px;
  color: ${(p) => p.theme.palette.lightGold};
  ${(p) => p.theme.breakpoints.up('sm')} {
    padding-right: 40px;
  }
`
const ListDivider = styled(Divider)`
  margin: ${(p) => p.theme.spacing(1)}px 0;
`
