import { CommentaryData, TeamColors } from '../types'
import React, { useEffect, useState, useRef } from 'react'
import { motion, useAnimation } from 'framer-motion'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import CommentaryItem from './commentary-item'
import LiveCommentary from './live-commentary'
import { SummaryContainer, CommentaryList } from '../styles'
import WaitingDots from 'src/common/components/waiting-dots'

type CommentaryProps = {
  commentary: CommentaryData
  teamColors: TeamColors
  isLive?: boolean
}
const Commentary = ({ commentary, isLive, teamColors }: CommentaryProps) => {
  const prevLength = useRef(commentary.length)

  useEffect(() => {
    prevLength.current = commentary.length
  })

  return (
    <CommentaryList>
      {commentary.length ? (
        commentary
          .map((itemData, i) => (
            <motion.div
              initial={i >= prevLength.current && { height: 0 }}
              animate={{ height: 'auto' }}
              transition={{
                duration: 0.25,
                delay: (i - prevLength.current) * 0.2,
              }}
              style={{ overflow: 'hidden' }}
              key={itemData.sequence}
            >
              <CommentaryItem
                data={itemData}
                {...{ teamColors }}
                isLastItem={i === 0}
              />
            </motion.div>
          ))
          .reverse()
      ) : (
        <div>
          <Typography variant="h6" component="h4" gutterBottom>
            Commentary
          </Typography>
          Nothing has happened yet{WaitingDots}
        </div>
      )}
    </CommentaryList>
  )
}

export default React.memo(Commentary)
