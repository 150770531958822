import { RosterPlayer, RosterTeam, Roster } from '../../types'
import React from 'react'
import styled from 'styled-components'
import Headshot from './headshot'
import InfoStats from './info-stats'
import PlayerInfo from './player-info'
import { getPlayerSubbedOutFor, getPlayerSubTime } from '../utils'

type InfoContentProps = {
  player: RosterPlayer
  teamColor: string
  // team: RosterTeam
  roster: Roster
}
const InfoContent = ({ player, teamColor, roster }: InfoContentProps) => {
  const { stats } = player

  const playerSubbedOutFor = getPlayerSubbedOutFor(player, roster)

  return (
    <Container>
      <HeadshotAndInfo>
        <Headshot
          src={player.athlete.headshot?.href}
          alt={player.athlete.headshot?.alt}
          color={teamColor}
        />
        <Info>
          <PlayerInfo
            name={player.athlete.displayName}
            number={player.jersey}
            team={roster.team.displayName}
          />
          {playerSubbedOutFor && (
            <SubInfo>
              Subbed ({getPlayerSubTime(player)}) for #
              {playerSubbedOutFor.jersey}&nbsp;
              {playerSubbedOutFor.athlete.displayName.charAt(0)}
              .&nbsp;{playerSubbedOutFor.athlete.lastName}
            </SubInfo>
          )}
        </Info>
      </HeadshotAndInfo>
      {(player.subbedIn || player.starter) && stats && (
        <InfoStats stats={stats} />
      )}
    </Container>
  )
}

export default InfoContent

const Container = styled.div`
  padding: ${(p) => p.theme.spacing(1)}px;
  /* color: ${(p) => p.theme.palette.primary.light}; */
  width: 250px;
`
const HeadshotAndInfo = styled.div`
  /* display: flex;
  justify-content: space-between; */
  display: grid;
  grid-template-columns: 75px 1fr;
  grid-gap: 2px;
  width: 100%;
`
const Info = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const SubInfo = styled.div`
  /* display: flex;
  justify-content: flex-end; */
  margin-top: 4px;
  text-align: right;
  font-weight: normal;
  font-style: italic;
  font-size: 0.9em;
`
