import { TeamColors } from '../../types'
import React, { useState } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'

// const { x, y, name, number, isHome } = player

type FieldProps = {
  children?: React.ReactNode | React.ReactNode[]
}
const Field = ({ children }: FieldProps) => {
  return (
    <FieldContainer>
      <FieldSvg viewBox="0 0 84 126">
        <g>
          {/* <FieldGrass
            x={strokeWidth}
            y={strokeWidth}
            width={`${84 - strokeWidth * 2}`}
            height={`${126 - strokeWidth * 2}`}
          /> */}
          {/* <rect x="38" y="3" width="8" height="2" stroke="#388e3c66"></rect>
          <rect x="38" y="121" width="8" height="2" stroke="#388e3c66"></rect> */}
          <rect x="5" y="5" width="74" height="116"></rect>
          <rect x="20" y="5" width="44" height="18"></rect>
          <rect x="32" y="5" width="20" height="6"></rect>
          <rect x="20" y="103" width="44" height="18"></rect>
          <rect x="32" y="115" width="20" height="6"></rect>
          <circle cx="42" cy="63" r="10"></circle>
          <line x1="5" x2="79" y1="63" y2="63"></line>
          <path d="M6 5 A1 1 0 0 1 5 6"></path>
          <path d="M78 5 A1 1 0 0 0 79 6"></path>
          <path d="M6 121 A1 1 0 0 0 5 120"></path>
          <path d="M78 121 A1 1 0 0 1 79 120"></path>
          <KickSpot cx="42" cy="17" r="0.5"></KickSpot>
          <KickSpot cx="42" cy="63" r="0.5"></KickSpot>
          <KickSpot cx="42" cy="109" r="0.5"></KickSpot>
          <path d="M34 23 A10 10 0 0 0 50 23"></path>
          <path d="M34 103 A10 10 0 0 1 50 103"></path>
        </g>
        {children}
      </FieldSvg>
    </FieldContainer>
  )
}

export default Field

const FieldContainer = styled.div`
  position: relative;
  /* width: 300px; */
  /* width: 100%; */
  /* width: 50%; */
  /* width: 75%; */
  /* padding-bottom: calc(100% * 8 / 5 * 0.5 + 5px); */
  /* padding-bottom: calc(100% * 3 / 4);
  padding-bottom: calc(100% * 4 / 3); */
  /* padding-bottom: calc(75% * 3 / 4); */
  /* margin: auto; */
  margin: 0 -${(p) => p.theme.spacing(1)}px;
  width: ${(p) => `calc(100% + ${p.theme.spacing(1) * 2}px)`};
  ${(p) => p.theme.breakpoints.up('sm')} {
    margin: 0 -${(p) => p.theme.spacing(2)}px;
    width: ${(p) => `calc(100% + ${p.theme.spacing(2) * 2}px)`};
  }
  overflow: hidden;
  /* height: fit-content; */
`
const FieldSvg = styled.svg`
  /* position: absolute; */
  /* top: -2%; */
  /* top: -5%; */
  /* top: 15px; */
  /* left: -5.5%; */
  /* left: 0; */
  /* transform: scale(1.12); */
  /* width: 100%; */
  height: 100%;
  /* height: 104%;
  height: 208%; */
  max-height: calc(92vh - 56px);
  margin: auto;
  /* width: 100%; */
  display: block;
  fill: transparent;
  /* stroke: #040; */
  /* stroke: ${(p) => p.theme.palette.success.dark}; */
  /* stroke: #095812; */
  stroke: #11771d;
  /* stroke: #388e3c; */
  stroke-width: 0.3;
  /* border: 2px solid rgba(255, 255, 255, 0.25); */
`
const KickSpot = styled.circle`
  fill: #040;
  fill: ${(p) => p.theme.palette.success.dark};
  stroke-width: 0;
`
