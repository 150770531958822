import React from 'react'
import styled from 'styled-components'

const Dots = styled.span`
  /* background-color: olivedrab; */
  span {
    opacity: 1;
  }
  span:nth-child(1) {
    animation: dot1 3s linear infinite;
  }
  span:nth-child(2) {
    animation: dot2 3s linear infinite;
  }
  span:nth-child(3) {
    animation: dot3 3s linear infinite;
  }
  @keyframes dot1 {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    25.01% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes dot2 {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    50.01% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes dot3 {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    75.01% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
`

const WaitingDots = (
  <Dots>
    <span>.</span>
    <span>.</span>
    <span>.</span>
  </Dots>
)

export default WaitingDots
