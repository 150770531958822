import React from 'react'
import styled, { css } from 'styled-components'
import { redCardColor, yellowCardColor } from './styles'

//TODO: refactor this disaster

type PlayIconProps = {
  text?: string
}
const PlayIcon = ({ text }: PlayIconProps) => {
  const lowercaseText = text?.toLowerCase() ?? ''

  if (lowercaseText.includes('yellow card')) return <Card />
  if (
    lowercaseText.includes('red card') ||
    lowercaseText.includes('second yellow card')
  )
    return <Card isRed />
  if (lowercaseText.includes('foul') || lowercaseText.includes('free kick'))
    return <Foul />
  if (/hand( ?)ball/gm.test(lowercaseText)) return <Handball />
  if (
    lowercaseText.includes('shot off target') ||
    lowercaseText.includes('attempt missed')
  )
    return <Missed />
  if (
    lowercaseText.includes('shot hit woodwork') ||
    /hits the .*(bar|post)/gm.test(lowercaseText)
  )
    return <Woodwork />
  if (lowercaseText.includes('blocked')) return <Blocked />
  if (
    lowercaseText.includes('shot on target') ||
    lowercaseText.includes('attempt saved') ||
    lowercaseText.includes('saves')
  )
    return <OnTarget />
  if (/assists?\b/gm.test(lowercaseText)) return <Assist />
  if (lowercaseText.includes('own goal')) return <Goal isOG />
  if (lowercaseText.includes('goal')) return <Goal />
  if (
    lowercaseText.includes('corner kick') ||
    lowercaseText.includes('corner,')
  )
    return <Corner />
  if (lowercaseText.includes('offside')) return <Offside />
  if (lowercaseText.includes('substitution')) return <Sub />
  if (lowercaseText.includes('half ends')) return <Time />
  if (lowercaseText.includes('var -') || lowercaseText.includes('var decision'))
    return <VAR />
  if (lowercaseText.toLowerCase().includes('home win')) return <HomeWinArrow />
  if (lowercaseText.toLowerCase().includes('away win')) return <AwayWinArrow />

  return <NoIcon />
}

//Shot Hit Woodwork

export default PlayIcon

const FontIcon = styled.span`
  font-family: 'ESPN-Icon-Font';
  font-weight: 400;
  /* color: ${(p) => p.theme.palette.grey[400]}bb; */
  /* color: ${(p) => p.theme.palette.lightGold}a6; */
  color: rgba(255, 255, 255, 0.7);
  /* color: ${(p) => p.theme.palette.primary.main}; */
`
const NoIcon = styled(FontIcon)`
  ::before {
    content: '-';
  }
`
const Foul = styled(FontIcon)`
  ::before {
    content: '\\3C';
  }
`
type CardProps = {
  isRed?: boolean
}
const Card = styled(FontIcon)<CardProps>`
  color: ${(p) => (p.isRed ? redCardColor : yellowCardColor)};
  ::before {
    content: '\\E060';
  }
`
const Handball = styled(FontIcon)`
  ::before {
    /* content: '\\A6'; */
    content: '\\2265';
  }
`
const Missed = styled(FontIcon)`
  ::before {
    content: '\\3E';
  }
`
const Woodwork = styled(FontIcon)`
  ::before {
    content: '\\E925';
  }
`
const Blocked = styled(FontIcon)`
  ::before {
    content: '\\203A';
  }
`
const OnTarget = styled(FontIcon)`
  ::before {
    content: '\\38';
  }
`
const Assist = styled(FontIcon)`
  /* color: #fff; */
  ::before {
    content: '\\E046';
  }
`
type GoalProps = {
  isOG?: boolean
}
const Goal = styled(FontIcon)<GoalProps>`
  color: ${(p) => (p.isOG ? redCardColor : '#fff')};
  ::before {
    content: '\\E093';
  }
`
const Corner = styled(FontIcon)`
  ::before {
    content: '\\36';
  }
`
const Offside = styled(FontIcon)`
  ::before {
    content: '\\34';
  }
`
const Sub = styled(FontIcon)`
  ::before {
    content: '\\E092';
  }
`
const Time = styled(FontIcon)`
  ::before {
    content: '\\32';
  }
`
const VAR = styled(FontIcon)`
  ::before {
    content: '\\E91F';
  }
`
const HomeWinArrow = styled(FontIcon)`
  ::before {
    content: '\\E087';
  }
`
const AwayWinArrow = styled(FontIcon)`
  ::before {
    content: '\\E088';
  }
`

export const goalIconCss = css`
  content: '\\E093';
  font-family: 'ESPN-Icon-Font';
  font-weight: 400;
  color: #fff;
`
export const redCardIconCss = css`
  content: '\\E060';
  font-family: 'ESPN-Icon-Font';
  font-weight: 400;
  color: ${redCardColor};
`
