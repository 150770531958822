import { Competitor } from '../types'
import React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import FluidClubLogo from 'src/common/components/team-logos/fluid-club-logo'
import { getClubInfo } from 'src/common/clubs'

type TopSectionTeamProps = {
  team: Competitor
  isHome?: boolean
}
const TopSectionTeam = ({ team, isHome }: TopSectionTeamProps) => {
  const clubInfo = getClubInfo(team.team.name)

  return (
    <LogoNameContainer isHome={isHome}>
      <LogoWrapper>
        <FluidClubLogo clubName={clubInfo.displayName} sizes="75px" />
      </LogoWrapper>
      <Abbrev>{clubInfo.abbrev}</Abbrev>
      <FullName>{clubInfo.displayName}</FullName>
    </LogoNameContainer>
  )
}

export default TopSectionTeam

type LogoNameContainerProps = {
  isHome?: boolean
}
const LogoNameContainer = styled.div<LogoNameContainerProps>`
  display: flex;
  /* flex-direction: ${(p) => (p.isHome ? 'column-reverse' : 'column')}; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-self: ${(p) => (p.isHome ? 'right' : 'left')};
  @media (min-width: 400px) {
    flex-direction: ${(p) => (p.isHome ? 'row-reverse' : 'row')};
  }
`
const LogoWrapper = styled.div`
  width: 40px;
  margin: 0 ${(p) => p.theme.spacing(1)}px;
  ${(p) => p.theme.breakpoints.up('md')} {
    margin: 0 ${(p) => p.theme.spacing(2)}px;
    width: 65px;
  }
`
const Abbrev = styled.span`
  font-size: 0.65em;
  ${(p) => p.theme.breakpoints.up('md')} {
    display: none;
  }
`
const FullName = styled(Abbrev)`
  display: none;
  ${(p) => p.theme.breakpoints.up('md')} {
    display: initial;
  }
`
