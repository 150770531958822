import { SetState } from 'src/common/types'
import React from 'react'
import styled from 'styled-components'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { TeamColors } from '../types'
import { spacing } from 'src/features/standings/styles'

type ViewToggleProps = {
  view: string
  setView: SetState<string>
}
const ViewToggle = ({ view, setView }: ViewToggleProps) => {
  const handleChange = (e: any, newSection: string | null) => {
    if (newSection !== null) {
      setView(newSection)
    }
  }
  return (
    <ToggleGroup value={view} onChange={handleChange} size="small" exclusive>
      <ToggleButton value="formation" disableRipple>
        formation
      </ToggleButton>
      <ToggleButton value="roster" disableRipple>
        roster
      </ToggleButton>
    </ToggleGroup>
  )
}

export default ViewToggle

const ToggleGroup = styled(ToggleButtonGroup)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* width: fit-content; */
  margin: auto;
  margin-top: ${spacing(1)};
  /* width: 200px; */
  /* width: 250px; */
  background-color: transparent;
  button {
    flex: 1 1 50%;
    font-weight: normal;
    height: ${(p) => p.theme.spacing(4)}px;
    font-size: 1.1em;
    color: rgba(255, 255, 255, 0.7);
    text-transform: uppercase;
    transition: ${(p) =>
      p.theme.transitions.create('background-color', {
        duration: p.theme.transitions.duration.shortest,
      })}
  }
  .Mui-selected {
    color: rgba(255, 255, 255, 0.80);
    font-weight: bold;
    /* color: ${(p) => p.theme.palette.primary.main}; */
    background-color: ${(p) => p.theme.palette.primary.main}55;
    &:hover {
      background-color: ${(p) => p.theme.palette.primary.main}77;
    }
  }
`
