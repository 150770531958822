import { StatValues, TeamColors } from '../types'
import React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { ChartLabel } from '../styles'
import FluidClubLogo from 'src/common/components/team-logos/fluid-club-logo'
import { spacing } from 'src/features/standings/styles'

type PossesionProps = {
  statValues: StatValues
  teamColors: TeamColors
  homeName: string
  awayName: string
}
const Possesion = ({
  statValues,
  teamColors,
  homeName,
  awayName,
}: PossesionProps) => {
  const { homeVal, awayVal } = statValues
  const homePercent =
    homeVal && awayVal
      ? homeVal + awayVal > 0
        ? (homeVal / (homeVal + awayVal)) * 100
        : 50
      : 50

  return (
    <OuterContainer>
      <LogoWrapper>
        <FluidClubLogo clubName={homeName} sizes="50px" />
      </LogoWrapper>
      <ChartLabel style={{ paddingBottom: 0 }}>Possession</ChartLabel>
      <LogoWrapper>
        <FluidClubLogo clubName={awayName} sizes="50px" />
      </LogoWrapper>

      <span>{homeVal}%</span>
      <div style={{ padding: `0 ${lineHeightPx / 2}px` }}>
        <Svg>
          <motion.line
            strokeWidth={lineHeightPx}
            stroke={teamColors.homeColor}
            x1="0"
            y1="50%"
            y2="50%"
            initial={false}
            animate={{
              x2: `${homePercent}%`,
              x: -1.5,
            }}
          />
          <motion.line
            strokeWidth={lineHeightPx}
            stroke={teamColors.awayColor}
            x1="100%"
            y1="50%"
            y2="50%"
            initial={false}
            animate={{
              x2: `${homePercent}%`,
              x: 1.5,
            }}
          />
        </Svg>
      </div>
      <span>{awayVal}%</span>
    </OuterContainer>
  )
}

export default Possesion

const OuterContainer = styled.div`
  display: grid;
  grid-template-columns: min-content auto min-content;
  grid-gap: ${spacing(1)};
  align-items: center;
  justify-items: center;
  padding: 4px 8px 16px 8px;
  font-size: 20px;
  font-weight: bold;
  @media (min-width: 375px) {
    font-size: 24px;
  }
`
const lineHeightPx = 16
const Svg = styled.svg`
  height: 100%;
  height: ${lineHeightPx}px;
  width: 100%;
  border-radius: ${lineHeightPx / 2}px;
`
const LogoWrapper = styled.div`
  width: 35px;
`
